// apiClient.js
import axios from 'axios';
import { useSession } from '../config/supabasecontext';

let ip = 'https://vieweredgeapi.mindir.io';
if (process.env.NODE_ENV === 'development') {
  ip = 'http://localhost:5001';
  // ip = 'https://api.stagingbes.mindir.io';
}

const apiClient = axios.create({
  baseURL: ip,
  // any other settings you want to set for the client
});

// Add a response interceptor
apiClient.interceptors.response.use(undefined, (error) => {
  if (error.config && error.response && error.response.status === 401) {
    // Token expired, try to refresh it
    // NOTE: Direct use of useSession here is problematic. This needs a different approach.
    console.log('Error 322');
    const session = useSession();
    console.log(session);
    return refreshToken(session).then((response) => {
      // Update the token in local storage or wherever you're storing it
      localStorage.setItem('token', response.data.token);
      // Update the token in the original request and retry it
      error.config.headers['Authorization'] = 'Bearer ' + response.data.token;
      console.log(axios(error.config));
    });
  } else if (error.response.status === 403) {
    console.log('403 error');
  } else {
    console.log('error', error);
  }
  console.log(error, 'newerror');
  return Promise.reject(error);
});

// Modified refreshToken function to accept session as a parameter
function refreshToken(session) {
  console.log(session, 'testtesttest');
  // Replace with your actual refresh token endpoint and refresh token
  return apiClient.post('/chrome/login', {
    token: session,
  });
}

export default apiClient;
