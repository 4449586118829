import React, { useState, useEffect } from 'react';
import Vieweranswerchoices from './vieweranswerChoices';
import ViewerBegin from './viewerbegin';
import Loading from './LoadingState';
import supabase from '../config/supabaseClient';
import apiClient from '../helpers/apiClient';
import { SessionProvider } from '../config/supabasecontext';

export default function VideoComponent({ currentStreamer }) {
  const [parentSession, setParentSession] = useState(null);
  const [active, setActive] = useState(false);
  const [question, setQuestion] = useState(null);
  const [leaderboard, setLeaderboard] = useState(null);
  const [leaderboardLoading, setLeaderboardLoading] = useState(false);
  const [leaderboardRefresh, setLeaderboardRefresh] = useState(false);
  const [cardOpenState, setCardOpenState] = useState(true);
  const [beganSuccessfully, setBeganSuccessfully] = useState(true);
  const [socketError, setSocketError] = useState('');
  const [authToken, setauthToken] = useState(null);
  const [refreshQuizState, setRefreshQuizState] = useState(null);
  const [refreshLeaderboardState, setRefreshLeaderboardState] = useState(null);
  const [currentViewerCredit, setCurrentViewerCredit] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [streamerQuestionValue, setStreamerQuestionValue] = useState(null);
  const [refreshCurrentCredit, setRefreshCurrentCredit] = useState(null);

  function attemptSocketConnection() {
    if (parentSession) {
      apiClient
        .post('/chrome/login', { token: parentSession })
        .then((response) => {
          if (response.data) {
            localStorage.setItem('token', response.data.access_token);
            setauthToken(response.data.access_token);
            setBeganSuccessfully(true);
          } else {
            setSocketError('Failed to connect to API');
          }
        });
    } else {
      setauthToken(null);
      setBeganSuccessfully(false);
    }
  }

  function startPolling() {
    if (streamerQuestionValue == null) {
      updateQuestionValueCallback();
      const handle = setInterval(updateQuestionValueCallback, 5000);
      setStreamerQuestionValue(handle);
    }
    if (refreshQuizState == null) {
      updateQuizCallback();
      const handle = setInterval(updateQuizCallback, 5000);
      setRefreshQuizState(handle);
    }
    if (refreshLeaderboardState == null) {
      updateLeaderboardCallback();
      const handle = setInterval(updateLeaderboardCallback, 60000);
      setRefreshLeaderboardState(handle);
    }
    if (refreshCurrentCredit == null) {
      getCurrentCredit();
      const handle = setInterval(getCurrentCredit, 3000);
      setRefreshCurrentCredit(handle);
    }
    getCurrentCredit();
  }
  function updateQuestionValueCallback() {
    const token = localStorage.getItem('token');

    if (currentStreamer == null) {
      return;
    }

    apiClient
      .get(`/chrome/streamer/sessions/${currentStreamer}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setStreamerQuestionValue(response.data[0].multiplier);
        console.log(response.data[0].multiplier);
      })
      .catch((error) => {
        if (error.response) {
          const { status } = error.response;
          if (status === 403 || status === 422) {
            setErrorStatus(status);
          }
        }
      });
  }

  function updateQuizCallback() {
    const token = localStorage.getItem('token');
    if (currentStreamer == null) {
      return;
    }
    apiClient
      .get('/chrome/quiz', {
        headers: {
          Authorization: `Bearer ${token}`,
          channel_username: currentStreamer,
        },
      })
      .then((response) => {
        setQuestion(response.data.quizzes);
      })
      .catch((error) => {
        if (error.response) {
          const { status } = error.response;
          if (status === 403 || status === 422) {
            setErrorStatus(status);
          }
        }
      });
  }

  function updateLeaderboardCallback() {
    const token = localStorage.getItem('token');

    if (currentStreamer === null) {
      return;
    }

    apiClient
      .get('/chrome/leaderboard', {
        headers: {
          Authorization: `Bearer ${token}`,
          channel_username: currentStreamer,
        },
      })
      .then((response) => {
        const sortedBoard = {
          daily: sortLeaderlist(response.data.leaderboard.daily),
          weekly: sortLeaderlist(response.data.leaderboard.weekly),
          monthly: sortLeaderlist(response.data.leaderboard.monthly),
        };
        setLeaderboardLoading(false);
        setLeaderboard(sortedBoard);
      })
      .catch((error) => {
        if (error.response) {
          const { status } = error.response;
          if (status === 403 || status === 422) {
            setErrorStatus(status);
          }
        }
      });
  }

  function getCurrentCredit() {
    const token = localStorage.getItem('token');

    apiClient
      .get('/chrome/currentCredit', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response, 'fixing');
        setCurrentViewerCredit(response.data.currentCredits.currentCredits);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function sortLeaderlist(obj) {
    return obj.sort((a, b) => {
      return a.position - b.position;
    });
  }

  function getPrizePool(quiz_id, question_id) {
    const token = localStorage.getItem('token');

    apiClient
      .get('/chrome/prize_pool', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          quiz_id: quiz_id,
          question_id: question_id,
        },
      })
      .then((response) => {
        console.log(response, 'fixing');
        // Assuming the response has a data structure similar to currentCredits
        setCurrentViewerPrizePool(response.data.prizePool); // Change the state updating function accordingly
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Change this to where once you submit answer you get your currentCredit deducted.
  function submitAnswer(answer) {
    const token = localStorage.getItem('token');

    apiClient
      .post(
        '/chrome/v2/answer/submit',
        {
          answer: answer,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            channel_username: currentStreamer,
          },
        }
      )
      .then((response) => {
        updateQuizCallback();
        getCurrentCredit();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function changeCardState(state) {
    setCardOpenState(state);
  }

  function updateLeaderboard() {
    setLeaderboardLoading(true);
    updateLeaderboardCallback();
  }

  function logout() {
    setParentSession(null);
    setauthToken(null);
    setBeganSuccessfully(false);
    setActive(false);
    setErrorStatus(false);
    supabase.auth.signOut();
  }

  useEffect(() => {
    const getSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error getting session:', error);
        setParentSession(null);
      } else {
        setParentSession(data.session);
      }
    };

    getSession();

    const { data: listener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setParentSession(session);
      }
    );

    return () => {
      listener.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (parentSession) {
      const cookie = localStorage.getItem('token');
      if (!cookie) {
        attemptSocketConnection();
      } else {
        setauthToken(cookie);
        setBeganSuccessfully(true);
      }
    } else {
      setauthToken(null);
      setBeganSuccessfully(false);
    }
  }, [parentSession]);

  useEffect(() => {
    if (authToken !== null && currentStreamer !== null) {
      startPolling();
    }
  }, [authToken, currentStreamer]);

  if (parentSession == null && !beganSuccessfully) {
    return (
      <SessionProvider>
        <ViewerBegin
          attemptSocketConnection={attemptSocketConnection}
          socketError={socketError}
        />
      </SessionProvider>
    );
  }

  if (
    question == null ||
    question.length === 0 ||
    currentViewerCredit == null
  ) {
    return (
      <SessionProvider>
        <Loading />
      </SessionProvider>
    );
  }

  return (
    <SessionProvider>
      <Vieweranswerchoices
        logout={logout}
        currentViewerCredits={currentViewerCredit}
        questions={question}
        submitAnswer={submitAnswer}
        leaderboardLoading={leaderboardLoading}
        leaderboardData={leaderboard}
        loadLeaderboard={updateLeaderboard}
        cardOpenSetter={changeCardState}
      />
    </SessionProvider>
  );
}
