import React from 'react';
import Navlogo from '../images/nav-logo.png';
import Depoimg from '../images/depo.png';
import Moneysymbol from '../images/money-symbol.png';

export default function NavBar({
  totalQuestions,
  currentViewerCredits,
  logout,
}) {
  return (
    <div className="row align-items-center py-2">
      <div className="col-4 ">
        <img className="nav-logo img-fluid" alt="logo" src={Navlogo} />
      </div>
      <div className="col-3 mt-2 d-flex align-items-center">
        <span className="gamebox">{totalQuestions}</span>
        <span className="game-text">Games</span>
      </div>
      <div className="col-3 mt-2 d-flex align-items-center">
        <img
          className="money-symbol me-2 img-fluid align-middle"
          alt="money"
          src={Moneysymbol}
        />
        <span className="money-text">{currentViewerCredits}</span>
      </div>
      <div className="col-2 mt-2 d-flex justify-content-center">
        <img onClick={logout} alt="depo" src={Depoimg} className="img-fluid" />
      </div>
    </div>
  );
}
