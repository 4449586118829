import { createClient } from '@supabase/supabase-js';

let URL = process.env.REACT_APP_SUPABASE_URL;
let KEY = process.env.REACT_APP_ANON_KEY;
console.log(process.env.REACT_APP_SUPABASE_URL);
const supabaseUrl = URL;
const supabaseKey = KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

export default supabase;
