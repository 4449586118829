import React from 'react';
import SubmitBtn from '../images/edge-submit.png';

export default function Vieweranswerconfirm(props) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <>
      <div className="confirm-box">
        <div
          className="go-back-txt-div"
          onClick={() => {
            props.showConfirmSetter(false);
          }}
        >
          <p className="go-back-txt-msg">&#60; GO BACK</p>
        </div>
        <div className="row qustion-text-div">
          <p className="col-12 question-text-msg">{props.questionTitle}</p>
        </div>
        <div className="row question-anschoice-div">
          <div className="col-12">
            <p className="your-answer-txt">Your Answer</p>
          </div>
        </div>
        <div className="row question-anschoice-div">
          <p className="col-12 question-ans-choice-msg">
            {props.questionAnswer}
          </p>
        </div>
        <div
          className="confirm-btn-div grayscale-img"
          onClick={() => props.submit()}
        >
          <img src={SubmitBtn} className="submitbtn1" />
        </div>
      </div>
    </>
  );
}
