import React from 'react';
import { useState } from 'react';
import AnsCheck from '../images/your-answer-check.png';
import twoX from '../images/2X-img.png';
import Dollar from '../images/selected-ans-dollar.png';

export default function Selectedanswer(props) {
  return (
    <>
      <div className="row selected-ans-bg">
        <div className="col-2 text-center">
          <img src={twoX} className="twoX" />
        </div>
        <div className="col-8 d-flex">
          <img src={Dollar} className="dollar" />
          {/* TODO this will need to be a working live credit count */}
          <p className="selected-answer-question">12,121</p>
        </div>
      </div>
      <div className="row selected-ans-bg">
        <div className="col-9 d-flex">
          <img src={AnsCheck} className="ans-check" />
          {/* TODO api call to get number of answers with this quizId.  */}
          <p className="selected-answer-name">{props.questionAnswer}</p>
        </div>
        <div className="col-3 d-flex justify-content-end">
          <p className="see-all-btn">
            see all <span>+</span>
          </p>
        </div>
      </div>
    </>
  );
}
