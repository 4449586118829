import React from 'react';
import { useEffect } from 'react';
import AnswerTimer from './AnswerTime';
import Vieweranswerconfirm from './vieweranswerconfirm';
import Selectedanswer from './selectedanswer';
import HistoricNoAnswer from './historicnoanswer';
import PercentCorrect from './/percentcorrect';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/frontend.css';
import ViewerBegin from './viewerbegin';
import { useSession } from '../config/supabasecontext';
import NavBar from './Navbar';
import Accordion from 'react-bootstrap/Accordion';
import SubmitBtn from '../images/edge-submit.png';
import Downarr from '../images/downarr.png';
import Ansdown from '../images/ansdown.png';
import twoX from '../images/2XMode.png';
import threeX from '../images/3XMode.png';
import Normal from '../images/NormalMode.png';
import DegenMode from '../images/DegenMode.png';
import LetItRideone from '../images/LetItRide.png';
import LetItRidetwo from '../images/LetItRide2.png';
export default function Vieweranserchoices(props) {
  const [loadingState, setLoadingState] = React.useState(false);
  const [currentQuestion, setCurrentQuestion] = React.useState(null);
  const [questions, setQuestions] = React.useState(null);
  const [isSelected, setIsSelected] = React.useState(null);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [startTest, setStartTest] = React.useState(false);
  const [totalQuestions, setTotalQuestions] = React.useState(0);
  const session = useSession();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  function showConfirmSetter(val) {
    setShowConfirm(val);
  }
  function calculateQuizOver(timeObj) {
    const time = new Date(timeObj);
    const now = new Date();
    const timeDiff = time - now;
    return timeDiff <= 0;
  }
  function getSortedKeys(obj) {
    const keys = Object.keys(obj).sort(function (a, b) {
      // convert end_time string to date object
      obj[a].start_time = new Date(obj[a].start_time);
      obj[b].start_time = new Date(obj[b].start_time);
      return obj[a].start_time - obj[b].start_time;
    });
    // reverse the array so that the most recent quiz is first
    const qKeys = keys.reverse();
    return qKeys;
  }

  function combineQuestions(newQuestions) {
    // if the questions state is null, we need to set the current question to the first quiz in the new state
    if (questions == null) {
      const firstQuestion = getSortedKeys(newQuestions)[0];
      setQuestions(newQuestions);
      setCurrentQuestion(firstQuestion);
    } else {
      for (let i = 0; i < questions.length; i++) {
        // if the current question is answered, we will transfer it to the new state
        if (questions[i].answered) {
          // lets see if the question is in the new state
          if (newQuestions[questions[i].id] != null) {
            // if the question is in the new state, we will transfer the answered value to the new state
            newQuestions[questions[i].id].answered = true;
          }
        }
      }
      setQuestions(newQuestions);
      // check if theres a new question in the new state so we can set it as the current question
      const qKeys = getSortedKeys(newQuestions);
      for (let i = 0; i < qKeys.length; i++) {
        // if the question is not in the old state, we will set it as the current question
        // except if the currently selected question is
        if (
          questions[currentQuestion].active == true &&
          questions[currentQuestion].answered == false
        ) {
          // we will not change the current question if the current question is active and has not been answered
          break;
        } else {
          // setCurrentQuestion(qKeys[i]);
          break;
        }
      }
    }
  }

  // Get the current date and time
  let currentDate = new Date();

  // Add 30 seconds to the current date and time
  currentDate.setSeconds(currentDate.getSeconds() + 30);

  // Get the ISO 8601 formatted timestamp
  let isoTimestamp = currentDate.toISOString();

  console.log(isoTimestamp);

  function dummyLeaderboard() {
    return null;
  }

  const exobj = {
    monthly: [{ display_name: 'JoyStickUser', pointsTotal: 5, position: 1 }],
    daily: [{ display_name: 'JoyStickUser', pointsTotal: 1, position: 1 }],
  };

  useEffect(() => {
    if (props.questions && props.questions.length > 0) {
      // make dict of questions
      const newState = {};
      setTotalQuestions(props.questions.length);
      console.log(totalQuestions);
      console.log(props.questions);
      for (let i = 0; i < props.questions.length; i++) {
        newState[props.questions[i].id] = props.questions[i];
      }
      // check if the currently selected quiz is still in the new state
      if (questions == null) {
        // if the questions state is null, we need to set the current question to the first quiz in the new state
        combineQuestions(newState);
        const firstQuestion = getSortedKeys(newState)[0];
        setCurrentQuestion(firstQuestion);
      } else if (newState[questions[currentQuestion].id] == null) {
        // if the currently selected quiz is not in the new state, we need to set the current question to the first quiz in the new state
        combineQuestions(newState);
        const firstQuestion = getSortedKeys(newState)[0];
        setCurrentQuestion(firstQuestion);
      } else {
        // we need to do several things, check if they are the same length, if the currently selected quiz is still in the new state, and
        combineQuestions(newState);
      }
    } else if (props.questions == null) {
      // empty question data back from backend
      const res = [
        {
          question: 'Who will get the most kills',
          answer_choices: ['Answer A', 'Answer B', 'Answer C', 'Answer D'],
          end_time: currentDate,
          answered: false,
          active: true,
        },
        {
          question: '2...',
          answer_choices: [
            'Answer A',
            'Answer B',
            'Answer C',
            'Answer D',
            'Answer E',
            'Answer F',
          ],
          end_time: currentDate,
          answered: false,
          active: true,
        },
        {
          question: '3...',
          answer_choices: [
            'How many kills will thi player get?',
            'Will I get a kill?',
            'Answer C',
            'Answer D',
            'Answer E',
            'Answer F',
          ],
          end_time: currentDate,
          answered: true,
          active: false,
        },
      ];
      //   setQuestions(res);
      //   setCurrentQuestion(0);
    }
  }, [props.questions]);

  function answerQuestion() {
    const answerObj = {
      answer: questions[currentQuestion],
    };
    answerObj['answer']['answer'] = isSelected;
    props.submitAnswer(answerObj['answer']);
    setShowConfirm(false);
    questions[currentQuestion]['answered'] = true;
    questions[currentQuestion]['quiz_answer'] = isSelected;
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  function startTestTrue() {
    setStartTest(true);
  }
  const GameMode = {
    Normal: Normal,
    mode2X: twoX,
    mode3X: threeX,
    modeLetitRide: LetItRideone,
    modeLetitRide2: LetItRidetwo,
    modeDegen: DegenMode,
    // Add more modes as needed
  };
  // if startTest == false, return ViewerBegin
  if (session == false) {
    return (
      <div className="background">
        <ViewerBegin startTestTrue={startTestTrue} />
      </div>
    );
  } else {
    // if we do not need to show the confirmation screen, show the question selection screen
    return (
      <div className="background">
        {currentQuestion != null && questions && (
          <div>
            <NavBar
              logout={props.logout}
              totalQuestions={totalQuestions}
              currentViewerCredits={props.currentViewerCredits}
            />
            <Accordion className="zero-padding" defaultActiveKey="0">
              {getSortedKeys(questions).map((question, index) => {
                return (
                  <Accordion.Item className="zero-padding" eventKey={index}>
                    <Accordion.Header className="zero-padding">
                      <div
                        key={question}
                        className={classNames(
                          questions[question].active !== false
                            ? 'question-opacity'
                            : 'not-current-question-opacity',
                          'row question-selection-hover border-q no-drag align-items-center'
                        )}
                        onClick={() => {
                          setCurrentQuestion(question);
                        }}
                      >
                        <div className="col-1 d-flex align-items-center justify-content-center">
                          <p
                            className={classNames(
                              questions[question].active === false
                                ? 'question-opacity non-active-m text-c'
                                : 'game-txt text-c',
                              'text-c mb-0'
                            )}
                          >
                            G{index + 1}
                          </p>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                          <p className="text-c question-txt mb-0 mr-2">
                            {questions[question].question}
                          </p>
                          <img
                            src={GameMode[questions[question].mode]}
                            className="mode-image"
                            style={{ marginLeft: '8px' }}
                          />
                        </div>
                        <div className="col-3 mt-3">
                          <p className="text-c question-selection-text question-selection-position">
                            {questions[question].hasOwnProperty('active') &&
                            questions[question].active === false ? (
                              <img src={Downarr} className="downarr" />
                            ) : questions[question].hasOwnProperty(
                                'answered'
                              ) &&
                              questions[question].answered === false &&
                              !calculateQuizOver(
                                questions[question].end_time
                              ) ? (
                              <span className="answer-drop-span">Answer</span>
                            ) : (
                              <span className="active-drop-span">Active</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="zero-padding">
                      {currentQuestion != null &&
                        questions[currentQuestion].correct_answer == null &&
                        questions[currentQuestion].answer_distribution ==
                          null &&
                        questions[currentQuestion].answered == false &&
                        !calculateQuizOver(
                          questions[currentQuestion].end_time
                        ) && (
                          <div className="viewer-card-body card-body">
                            <div className="row time-row">
                              <div className="col-md-8 timer-wrapper">
                                <AnswerTimer
                                  endtime={questions[currentQuestion].end_time}
                                />
                              </div>
                            </div>
                            <div className="container answer-block">
                              {questions[currentQuestion].answer_choices.map(
                                (answer, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={classNames(
                                        index == isSelected
                                          ? 'answer-button row purple-border answer-row-margin'
                                          : 'answer-button row answer-row-margin'
                                      )}
                                      onClick={() => {
                                        setIsSelected(index);
                                      }}
                                    >
                                      <div className="col-1 remove-padding">
                                        <div
                                          className={classNames(
                                            index == isSelected
                                              ? ' type3'
                                              : 'type2',
                                            'circleBase '
                                          )}
                                        >
                                          <div
                                            className={classNames(
                                              index == isSelected
                                                ? ' type6'
                                                : '',
                                              'circleBase1 '
                                            )}
                                          ></div>
                                        </div>
                                      </div>
                                      <div className="answer-choice-txt col-9">
                                        <p className="choice-answer">
                                          {answer}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        )}
                      {currentQuestion != null &&
                        questions[currentQuestion].answered == true &&
                        questions[currentQuestion].answer_distribution ==
                          null && (
                          <Selectedanswer
                            questionTitle={questions[currentQuestion].question}
                            questionAnswer={
                              questions[currentQuestion].answer_choices[
                                questions[currentQuestion].quiz_answer
                              ]
                            }
                            leaderboardLoading={props.leaderboardLoading}
                            leaderboardData={props.leaderboardData}
                            loadLeaderboard={props.loadLeaderboard}
                          />
                        )}
                      {currentQuestion != null &&
                        calculateQuizOver(
                          questions[currentQuestion].end_time
                        ) &&
                        questions[currentQuestion].answered == false && (
                          <HistoricNoAnswer
                            questionTitle={questions[currentQuestion].question}
                            leaderboardLoading={props.leaderboardLoading}
                            leaderboardData={props.leaderboardData}
                            loadLeaderboard={props.loadLeaderboard}
                            answerChoices={
                              questions[currentQuestion].answer_choices
                            }
                          />
                        )}
                      {currentQuestion != null &&
                        questions[currentQuestion].answer_distribution !=
                          null &&
                        questions[currentQuestion].correct_answer != null && (
                          <PercentCorrect
                            answerDistribution={
                              questions[currentQuestion].answer_distribution
                            }
                            questionTitle={questions[currentQuestion].question}
                            answerChoices={
                              questions[currentQuestion].answer_choices
                            }
                            correctAnswer={
                              questions[currentQuestion].correct_answer
                            }
                          />
                        )}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
            {currentQuestion != null &&
              questions[currentQuestion].active == true &&
              calculateQuizOver(questions[currentQuestion].end_time) == false &&
              questions[currentQuestion].answered != true &&
              showConfirm == false && (
                <div
                  className="row g-0 submitbtnrowalign"
                  style={{ marginTop: 'auto' }}
                >
                  <div className="col-12">
                    <div
                      className="submitbtn1 grayscale-img g-0"
                      onClick={() => setShowConfirm(true)}
                    >
                      <img className="submitbtn" src={SubmitBtn} />
                    </div>
                  </div>
                </div>
              )}
            {showConfirm && (
              <Vieweranswerconfirm
                showConfirmSetter={showConfirmSetter}
                questionTitle={questions[currentQuestion].question}
                questionAnswer={
                  questions[currentQuestion].answer_choices[isSelected]
                }
                submit={answerQuestion}
                cardOpenSetter={props.cardOpenSetter}
                leftSideSetter={props.leftSideSetter}
                leftSide={props.leftSide}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
