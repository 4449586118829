import React from 'react';

export default function SelectStreamerPage({ streamers, setCurrentStreamer }) {
  console.log('SelectStreamerPage');
  console.log(streamers);
  const handleClick = (streamer) => {
    console.log(streamer);
    setCurrentStreamer(streamer);
  };

  const placeholderIconStyle = {
    width: '50px',
    height: '50px',
    backgroundColor: '#555',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '24px',
  };

  // Array of streamers
  // const streamers = [
  //   { id: 1, name: 'Amouranth' },
  //   { id: 2, name: 'gamer123' },
  //   { id: 3, name: 'streamerXYZ' },
  //   { id: 4, name: 'streamerAlpha' },
  //   { id: 5, name: 'streamerBeta' },
  //   { id: 6, name: 'streamerGamma' },
  //   // Add more streamers as needed
  // ];

  return (
    <div
      style={{
        marginTop: '5%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: '1200px',
          marginBottom: '20px',
        }}
      >
        {/* Live Now Button */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#1e1e1e',
            padding: '5px 10px',
            borderRadius: '5px',
            marginRight: 'auto', // Align to the left
          }}
        >
          <div
            style={{
              width: '10px',
              height: '10px',
              backgroundColor: 'red',
              borderRadius: '50%',
              marginRight: '5px',
            }}
          ></div>
          <span style={{ color: '#fff', fontSize: '16px' }}>Live Now</span>
        </div>
      </div>

      {/* Streamer Boxes */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gap: '20px',
          width: '100%',
          maxWidth: '1200px',
        }}
      >
        {streamers.map((streamer) => (
          <div
            key={streamer.id}
            onClick={() => handleClick(streamer)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: '#2c2c2c',
              borderRadius: '10px',
              padding: '20px',
            }}
          >
            <div
              style={{
                width: '100px',
                height: '100px',
                backgroundColor: '#444',
                borderRadius: '50%',
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={placeholderIconStyle}>?</div>
            </div>
            <span style={{ color: '#fff', fontSize: '16px' }}>{streamer}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
