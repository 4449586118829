import React from 'react';
import { useState } from 'react';
import People from '../images/statspeople.png';
import twoX from '../images/2X-img.png';
import Dollar from '../images/selected-ans-dollar.png';
import Goldans from '../images/gold-ans.png';
import Emptyans from '../images/empty-ans.png';
import Goldppl from '../images/gold-ppl.png';
import Emptyppl from '../images/empty-ppl.png';
// This the the Option that shows the post quiz answer distrubtion
export default function PercentCorrect(props) {
  return (
    <>
      <div className="row selected-ans-bg">
        <div className="col-2 text-center">
          <img src={twoX} className="twoX" />
        </div>
        <div className="col-8 d-flex">
          <img src={Dollar} className="dollar" />
          {/* TODO this will need to be a live credit count for this quiz. */}
          <p className="selected-answer-question">12,000</p>
        </div>
        <div className="col-2 text-center">
          {props.answerDistribution.total_answers}
        </div>
      </div>
      <div className="bg-color">
        {props.answerChoices.map((answerChoice, index) => {
          return (
            <div className="row height-setter" key={index}>
              <div className="col-2 text-center">
                <p
                  className={
                    props.correctAnswer == index
                      ? 'percent-correct-number-txt-correct mt-1'
                      : 'percent-correct-number-txt mt-1'
                  }
                >
                  {props.correctAnswer == index && (
                    <img src={Goldans} className="gold-ans" />
                  )}
                  {props.correctAnswer != index && (
                    <img src={Emptyans} className="empty-ans" />
                  )}
                </p>
              </div>
              <div className="col-6">
                <p
                  className={
                    props.correctAnswer == index
                      ? 'percent-correct-answers-txt-correct mt-2'
                      : 'percent-correct-answers-txt percent-correct-answers-txt-wrong'
                  }
                >
                  {props.answerChoices[index]}
                </p>
              </div>
              <div className="col-2 text-end">
                <p
                  className={
                    props.correctAnswer == index
                      ? 'percent-correct-percentage-correct mt-2'
                      : 'percent-correct-percentage mt-1'
                  }
                >
                  {props.answerDistribution.answer_distribution[index]}
                </p>
              </div>
              <div className="col-2">
                {props.correctAnswer == index && (
                  <img src={Goldppl} className="gold-ppl" />
                )}
                {props.correctAnswer != index && (
                  <img src={Emptyppl} className="empty-ppl" />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
