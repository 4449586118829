import React, { useState, useEffect } from 'react';
import VideoComponent from './components/videocomponent';
import ChatBox from './components/ChatBox';
import SelectStreamerPage from './components/selectStreamerPage'; // Assuming the correct import path
import apiClient from '../src/helpers/apiClient';

function App() {
  const [currentStreamer, setCurrentStreamer] = useState(null);
  const [streamers, setStreamers] = useState(null);
  function fetchStreamers() {
    return apiClient.get('/v1/streamers').then((response) => {
      return response.data;
    });
  }
  useEffect(() => {
    if (streamers) return;
    fetchStreamers().then((streamers) => {
      console.log(streamers);
      setStreamers(streamers);
    });
  }, []);
  const appStyle = {
    display: 'grid',
    gridTemplateColumns: '75% 25%',
    height: '100vh',
    backgroundColor: '#1F2937', // Tailwind's gray-800
  };

  const leftSideStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px', // Tailwind's p-4
  };

  const rightSideStyle = {
    display: 'flex',
    flexDirection: 'column',
  };

  const videoComponentStyle = {
    flex: '0 0 75%', // Take up 75% of the height
    overflowY: 'auto',
  };

  const chatBoxStyle = {
    flex: '0 0 25%', // Take up 25% of the height
    overflowY: 'auto',
  };
  console.log(currentStreamer);
  return (
    <div style={appStyle}>
      <div style={leftSideStyle}>
        {currentStreamer == null && streamers ? (
          <SelectStreamerPage
            streamers={streamers}
            setCurrentStreamer={setCurrentStreamer}
          />
        ) : (
          <iframe
            id="kick-player"
            src={`https://player.kick.com/${currentStreamer}`}
            height="100%"
            width="100%"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
          ></iframe>
        )}
      </div>
      <div style={rightSideStyle}>
        <div style={videoComponentStyle}>
          <VideoComponent currentStreamer={currentStreamer} />
        </div>
        <div style={chatBoxStyle}>
          <ChatBox />
        </div>
      </div>
    </div>
  );
}

export default App;
