import React from 'react';
import { useState } from 'react';
import AnsCheck from '../images/your-answer-check.png';
import twoX from '../images/2X-img.png';
import Dollar from '../images/selected-ans-dollar.png';

export default function Selectedanswer(props) {
  const [showAnswers, setShowAnswers] = useState(false);

  return (
    <div className="light-bg">
      <div className="row selected-ans-bg">
        <div className="col-2 text-center">
          <img src={twoX} className="twoX" />
        </div>
        <div className="col-8 d-flex">
          <img src={Dollar} className="dollar" />
          {/* TODO this will need to be a live credit count for this quiz. */}
          <p className="selected-answer-question">12,000</p>
        </div>
      </div>
      <div className="row selected-ans-bg">
        <div className="col-9">
          <p className="selected-answer-name-no-answer">You didn't play</p>
        </div>
        <div className="col-3 d-flex justify-content-end">
          <p
            onClick={() => setShowAnswers(!showAnswers)}
            className="see-all-btn"
          >
            see all <span>+</span>
          </p>
        </div>
      </div>
      {showAnswers == true && (
        <div className="row light-bg mt-2">
          {props.answerChoices.map((answerChoice, index) => {
            return (
              <div className="col-12">
                <p className="no-answer-text">{props.answerChoices[index]}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
