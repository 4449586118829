import React from 'react';
import Logo from '../images/edge-logo.png';
import { useState, useEffect } from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import Loadingsquares from '../images/loading-squares.png';

export default function LoadingSpinner() {
  const [currentLine, setCurrentLine] = useState(1);
  const loadingText = {
    1: 'Warming up the crowd...',
    2: 'Cueing the suspense...',
    3: 'Sharpening our pixels...',
    4: 'Fresh excitement loading...',
    5: "Tighten your laces, you're going in.",
    6: 'Rolling out the red carpet...',
    7: 'Prepping the digital arena.',
    8: 'Firing up the entertainment.',
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentLine((prevLine) =>
        prevLine < Object.keys(loadingText).length ? prevLine + 1 : 1
      );
    }, 2000); // Change the interval duration as needed

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="loading-background">
        <div className="row d-flex justify-content-center">
          <FadeLoader
            height={25}
            width={10}
            margin={20}
            className="loader-margin"
            color="#fce552"
          />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-12 text-center mt-5">
            <img src={Logo} className="logo" />
          </div>
        </div>
        <div className="row d-flex text-center mt-5">
          <h1 className="loading-text text-white">Booting Up...</h1>
          <p className="mt-3 text-white loading-text-p ">
            {loadingText[currentLine]}
          </p>
        </div>
        <img alt="loading-squares" src={Loadingsquares} className="LS" />
      </div>
    </>
  );
}
