import React, { useState } from 'react';
import supabase from '../config/supabaseClient';
import Begin from '../images/goldbtn.png';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        console.error(error);
        // Handle error appropriately
      } else {
        if (data.session !== null) {
          console.log(data);
          return data;
        }
      }
    } catch (error) {
      console.error('Error signing in:', error.message);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <input
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="row margin-top-10 " style={{ marginBottom: '10px' }}>
        <div className="col-12 text-center mt-2">
          <input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="row text-center margin-top-10">
        <div className="col-12">
          <div className="image-container on-hover" onClick={handleSignIn}>
            <img alt="begin" src={Begin} className="viewerbegin-confirm-btn" />
            <div className="begin-text">BEGIN</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
