import React, { useState } from 'react';

function ChatBox() {
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      // Handle message submission logic here
      console.log('Message submitted:', message);
      setMessage(''); // Clear the input field after submission
    }
  };

  const chatBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '10px',
  };

  const messageListStyle = {
    flex: 1,
    overflowY: 'auto',
    marginBottom: '10px',
  };

  const formStyle = {
    display: 'flex',
  };

  const inputStyle = {
    flex: 1,
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px 0 0 5px',
  };

  const buttonStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '0 5px 5px 0',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
  };

  return (
    <div style={chatBoxStyle}>
      <div style={messageListStyle}>
        {/* Messages will be displayed here */}
      </div>
      <form style={formStyle} onSubmit={handleSubmit}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={inputStyle}
          placeholder="Type your message..."
        />
        <button type="submit" style={buttonStyle}>
          Send
        </button>
      </form>
    </div>
  );
}

export default ChatBox;
