import React, { useEffect } from 'react';
import supabase from '../config/supabaseClient';
import SignIn from './Createuser';
import { useSession } from '../config/supabasecontext';
import Squares from '../images/bottom-denied.png';
import Logo from '../images/edge-logo.png';

export default function ViewerBegin(props) {
  // function classNames(...classes) {
  //   return classes.filter(Boolean).join(" ");
  // }

  const session = useSession();

  useEffect(() => {
    if (session !== null) {
      // props.setHasUser(session);
    }
  }, [session]);

  if (!session) {
    return (
      <>
        <div className="access-denied-background">
          <div className="row text-center login-margin ">
            <div className="col-12 margintop-25 ">
              <img alt="logo" src={Logo} className="logo" />
            </div>
          </div>
          <div className="row margintop-20">
            <div className="col-12 text-center">
              <p className="viewerbegin-title mt-3">Login to Edge</p>
            </div>
          </div>
          <SignIn />
          <img alt="bottomDenied" src={Squares} className="ADsquares" />
        </div>
      </>
    );
  } else {
    return <div>Welcome</div>;
  }
}

// {/* <div className="row ">
// <div className="col-12 text-center">
//   <input placeholder="Username"></input>
// </div>
// </div>
// <div className="row margin-top-10">
// <div className="col-12 text-center">
//   <input placeholder="Password"></input>
// </div>
// </div>
// <div className="row text-center margin-top-10">
// <div className="col-12 ">
//   <div className="on-hover" onClick={() => props.startTestTrue()}>
//     <img
//       alt="begin"
//       src={Begin}
//       className="viewerbegin-confirm-btn"
//     />
//     {/* <p className="begin-text">BEGIN</p> */}
//   </div>
// </div>
// </div> */}
